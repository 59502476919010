.solution-content-section {
    color: #fff;
    max-width: 1300px; 
    margin: 40px auto; 
}

.product-content-subtitle {
    margin-bottom: 34px;
    line-height: 24px;
    color: #696969;
}

.product-content-subtitle p {
    font-size: 20px;
}

.fullscreen {
    height: 84vh;
}

.product-content-subtitle-marginTop {
    margin-top: 40px;
}

.content-row {
    align-items: center;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
}

.content-text-wrapper {
    max-width: 540px;
    padding-top: 0;
}

.solution-title-wrapper {
    text-align: left;
}

.content-header {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.dark {
    color: #696969;
}

.content-subtitle {
    max-width: 500px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 24px;
}

@media screen and (max-width: 1024px) {
    .solution-content-section{
        margin: 20px;
    }

    .fullscreen {
        height: fit-content;
    }

    .product-content-subtitle-marginTop {
        margin-top: 20px;
    }

    .product-content-subtitle p {
        font-size: 16px;
        text-align: left;
    }

    .solution-title-wrapper {
        margin-bottom: 16px;
        margin-top: 12px;
        text-align: left;
    }

    .product-content-subtitle{
        margin-bottom: 0px;
    }

    .buttons-section {
        justify-content: left !important;
    }

    .container-sol {
        padding-right: 20px !important;
        padding-left: 20px !important;
        padding-bottom: 40px !important;
        padding-top: 20px !important;
    }

    .content-img {
        padding: 0;
    }

    .col {
        padding: 0px;
    }

    .sol-col {
        padding: 0px 20px 0px 20px;
    }

    .content-section {
        padding: 30px;
        padding-bottom: 0px;
        height: fit-content;
        margin: 0px;
    }

    .content-subtitle {
        font-size: 16px;
        margin-bottom: 16px;
        text-align: left;
    }

    .content-header {
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .solution-content-section {
        margin: 16px;
        padding-top: 0px; 
    }

    .content-header {
        margin-bottom: 16px;
        text-align: center;
    }

    .content-text-wrapper {
        padding-bottom: 0px;
    }

    .row {
        flex-direction: column !important;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 0px;
    }

    .sol-col {
        margin-bottom: 20px;
    }

    .content-subtitle {
        text-align: center;
    }

    .buttons-section {
        justify-content: center !important;
    }
}
