.credit-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
  /* background-color: yellow; */
}

.credit-section {
  padding: 60px 160px;
  /* background-color: red; */
}

.credit-img {
  vertical-align: middle;
  height: 110px;
  margin-bottom: 0px;
  margin-left: 24px;
  /* background-color: green; */
}

.credit-container {
  display: grid;
  /* grid-gap: 2em;   */
  grid-template-columns: auto auto auto auto;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
}

.credit-details {
  /* background-color: pink; */
  padding: 1em;
  justify-content: center;
  text-align: center;
}

.top-credit-details {
  padding: 1em;
  justify-content: center;
  text-align: center;
  margin: auto;
  display: flex;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.top-credit-details p {
  font-weight:600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 24px;
}

.credit-details p {
  font-weight:600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 24px;
}

.credit-details span {
  font-weight:300;
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .credit-section {
    padding: 20px 20px;
  }

  .credit-img {
    vertical-align: middle;
    width: auto;
    height: 80px;
    margin-bottom: 20px;
    margin-left: 8px;

  }

  .credit-header {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .credit-details {
    /* background-color: pink; */
    padding: 0em;
    justify-content: center;
    text-align: center;
  
  }

  .top-credit-details {
    padding: 0em;
    justify-content: center;
    text-align: center;
    display: grid;
    grid-gap: 0px;
  }

.credit-details p {
  font-size: 18px;
  margin-bottom: 12px;
}

.top-credit-details p {
  font-size: 18px;
  margin-bottom: 12px;
}

.credit-details span {
  font-size: 14px;
}

}
