.donate-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.donate-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 40px;
}

.donate-details-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 10px;
}

.italic{
  font-style: italic;
}

.underline{
  text-decoration: underline;
}

.vs-middle {
  background-color: '#2f0ef';
}

.donate-section {
  padding: 0px 160px 40px 160px;
  background-color: #c2f0ef;
}

.donate-img {
  border-radius: 50%;
  vertical-align: middle;
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}

.donate-container {
  display: grid;
  grid-gap: 1em;
  /* grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
}

.donate-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
}

.donate-details p {
  font-weight:600;
  font-size: 16px;
  color: #696969;
  margin-bottom: 4px;
}

.donate-details span {
  font-weight:300;
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
  text-align: center;
}

.donate-details :hover {
  transform: scale(1.06);
  transition: 0.3s ease-out;
}

@media screen and (max-width: 960px) {
  .donate-section {
    padding: 20px 20px 40px 20px;
  }

  .donate-header {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .donate-subtitles{
    margin-bottom: 36px;
  }

.donate-details p {
  font-size: 18px;
  margin-bottom: 8px;
}

.donate-details span {
  font-size: 14px;
}

.donate-container {
  grid-gap: 1em;
}
}
