.events-container {
  /* ``min-height: 100vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background-color: #fff;
  padding-bottom: 80px;
}

.timeline {
  width: 90%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.timeline ul {
  list-style: none;
}

.timeline ul li {
  padding: 20px;
  background-color: #c2f0ef;
  color: black;
  border-radius: 10px;
  margin-bottom: 20px;
}

.timeline ul li:last-child {
  margin-bottom: 0;
}

.timeline-content h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}

.timeline-content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}

.timeline-content .date {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 768px) {
  .events-container {
    padding-top: 0px !important;
  }

  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: gray;
  }

  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: -70px;
  }

  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-30px);
    border-radius: 20px 0px 20px 20px;
  }

  .timeline ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
    border-radius: 0px 20px 20px 20px;
  }

  .timeline ul li::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: gray;
    top: 0px;
  }

  .timeline ul li:nth-child(odd)::before {
    transform: translate(50%, -50%);
    right: -30px;
  }

  .timeline ul li:nth-child(even)::before {
    transform: translate(-50%, -50%);
    left: -30px;
  }

  .timeline-content .date {
    position: absolute;
    top: -30px;
  }

  .timeline ul li:hover::before {
    background-color: aqua;
  }
}

@media screen and (max-width: 767px) {
  .events-container {
    padding-top: 0px !important;
    padding-bottom: 8px !important;
  }
  .team-container {
    
  }

  .timeline ul{
    padding-left: 0px;
  }
}
