/* .donate_subheaders {
  margin-bottom: 16px;
  font-size: 18px;
  color: #696969;
} */

.endheader_donate_wrapper {
  text-align: center;
  max-width: 1000px;
  margin-top: 8px;
}

.donate_top_banner_wrapper {
    justify-content: center;
    align-items: center;
    padding: 20px 160px;
    position: relative;
    background: #17BEBB;
    border-radius: 4px;
    max-width: 1200px;
    min-width: 800px;
    top: -40px;
}
  
.donate_header_wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 16px;
    position: relative;
    justify-content: center;
    align-items: center; 
}

.donate-middle-section {
  background-color: #c2f0ef;
  padding: 0px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .endheader_donate_wrapper {
    padding: 0px;
  }
  .header_wrapper {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px;
    top: 0px;
    background: none;
    color: #696969;
    padding-bottom: 16px;
  }

  .donate-middle-section {
    padding: 40px 20px 0px 20px;
  }

  .donate_top_banner_wrapper {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px;
    top: 0px;
    background: none;
    color: #696969;
    padding-bottom: 16px;
  }
}
