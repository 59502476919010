.navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-color: #fff;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;

    color: #17bebb;
    align-items: center;
    text-decoration: none;
    padding: 0rem;
    height: 100%;
}


/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    text-align: left;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: #17bebb;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1;
    
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.container {
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.navbar ul {
    margin-bottom: 0px;
}

.navbar-logo {
    color: #17bebb;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}


.nav-item:hover {
    border-bottom: 2px solid #17bebb;
}

.nav-links {
    color: #17bebb;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.fa-bars {
    color: #17bebb;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .navbar img {
        vertical-align: top;
    }
    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: none;
    }

    .navbar ul {
        padding-left: 0px;
    }

    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* height: 90vh; */
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: rgb(236, 234, 234);
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 1.4rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        color: #fff;
        transform: scale(1.2);
        transition: all 0.3s ease;
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        height: 80px;
        width: 100%;
        border-bottom: 2px solid transparent;
    }
    
    .dropdown {
        height: 80px;
        width: 100%;
        border-bottom: 2px solid transparent;

        position: relative;
        display: inline-block;
    
        color: #17bebb;
        align-items: center;
        text-decoration: none;
        padding: 0rem;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
        padding-top: 4px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #17bebb;
    }

    .fa-times {
        color: #17bebb;
        font-size: 2rem;
    }

    .nav-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
    }
}
