:root {
  --primary: #17BEBB;
}

.btn {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  outline: none !important;
  border: none !important;
  cursor: pointer !important;
  white-space: nowrap !important;
}

.btn--primary {
  background-color: var(--primary) !important;
  color: #fff !important;
  border: 1px solid var(--primary) !important;
}

.btn--outline {
  background-color: white !important;
  color: #17BEBB !important;
  padding: 8px 20px !important;
  border: 1px solid var(--primary) !important;
  transition: all 0.3s ease-out !important;
}

.btn--medium {
  padding: 8px 20px !important;
  font-size: 18px !important;
}

.btn--large {
  padding: 8px 26px !important;
  font-size: 20px !important;
}

.btn--mobile {
  text-align: center !important;
  border-radius: 4px !important;
  width: 80% !important;
  text-decoration: none !important;
  font-size: 1.5rem !important;
  background-color: #17BEBB !important;
  color: #fff !important;
  padding: 14px 20px !important;
  border: 1px solid #17BEBB !important;
  transition: all 0.3s ease-out !important;
}

.btn--wide {
  padding: 8px 64px !important;
  font-size: 20px !important;
}

.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out !important;
  background: #fff !important;
  color: #17BEBB !important;
}

.btn--large:hover {
  color: #fff !important;
  background-color: #17BEBB !important;
  transition: all 0.2s ease-out !important;
}

.btn-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-decoration: none !important;
  padding: 8px 16px !important;
  height: 100% !important;
  width: 100% !important;
}

.btn--primary:hover {
  background: #fff !important;
  color: #17BEBB !important;
  border: 1px solid #17BEBB !important;
}
