@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

@media screen and (max-width: 1024px) {
    #page-container {
        padding-bottom: 5rem;
    }
}

/* #content-wrap {
    padding-bottom: 2.5rem;  
  } */

.navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-color: #fff;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;

    color: #17bebb;
    align-items: center;
    text-decoration: none;
    padding: 0rem;
    height: 100%;
}


/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    text-align: left;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: #17bebb;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1;
    
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.container {
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.navbar ul {
    margin-bottom: 0px;
}

.navbar-logo {
    color: #17bebb;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}


.nav-item:hover {
    border-bottom: 2px solid #17bebb;
}

.nav-links {
    color: #17bebb;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.fa-bars {
    color: #17bebb;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .navbar img {
        vertical-align: top;
    }
    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: none;
    }

    .navbar ul {
        padding-left: 0px;
    }

    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* height: 90vh; */
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: rgb(236, 234, 234);
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 1.4rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        color: #fff;
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: all 0.3s ease;
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        height: 80px;
        width: 100%;
        border-bottom: 2px solid transparent;
    }
    
    .dropdown {
        height: 80px;
        width: 100%;
        border-bottom: 2px solid transparent;

        position: relative;
        display: inline-block;
    
        color: #17bebb;
        align-items: center;
        text-decoration: none;
        padding: 0rem;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translate(25%, 50%);
                transform: translate(25%, 50%);
        padding-top: 4px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
                transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #17bebb;
    }

    .fa-times {
        color: #17bebb;
        font-size: 2rem;
    }

    .nav-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
    }
}

.content-section {
  color: #fff;
  padding: 80px;  
}

.fullscreen {
  height: 84vh;
}

.buttons-section {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}

.blue-background {
  background-color: #c2f0ef;
}

.topheader {
  color:#696969;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  flex-direction: wrap;
  margin-bottom: 40px;
}

.data_section {
  /* background: #17BEBB; */
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.data_section ul {
  flex-direction: row;
  justify-content: center;
  display: flex;
  list-style: none;
}

.data_section span {
  font-size: 16px;
  font-weight: 300;
  color: #696969;
}

.data_section p {
  font-size: 40px;
  font-weight: 600;
  color: #696969;
}

.data_section li {
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 20px;
  padding-right: 16px;
  list-style: none;
}


.content-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
  max-width: 50%;
  flex-basis: 50%;
}

.content-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  /* padding-bottom: 60px; */
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.content-title-wrapper {
    margin-bottom: 16px;
    text-align: left;
}

.content-header {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.dark {
  color: #696969;
}

.content-subtitle {
  max-width: 500px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
}

.pointer-subtitle {
    /* max-width: 500px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px; */
}

.content-img-wrapper {
  max-width: 555px;
}

.content-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  /* .btn--large {
    padding:  10px 20px !important;
    font-size: 16px !important;
  } */

  .fullscreen {
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
  }

  .content-title-wrapper {
    margin-bottom: 12px;
    margin-top: 12px;
    text-align: center;
}

  .content-img-wrapper {
    justify-content: center !important;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  

  .buttons-section {
    justify-content: left !important;
  }

  .container {
    padding-right: 30px !important;
    padding-left: 30px !important;
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }

  .content-img {
    padding: 0;
  }
  
  .col {
    padding: 0px;
  }

  .content-section {
    padding: 0px;
    padding-bottom: 0px;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
  }

  .content-subtitle {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: left;
  }

  .content-header {
    font-size: 24px;
    margin-bottom: 16px;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .content-header {
    margin-bottom: 16px;
    text-align: center;
  }

  .content-text-wrapper {
    padding-bottom: 0px;
  }
  
  .content-img {
    padding: 0;
  }
  
  .row {
    flex-direction: column !important;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 0px;
  }

  .content-subtitle {
    text-align: center;
  }

  .buttons-section {
    justify-content: center !important;
  }
}

:root {
  --primary: #17BEBB;
}

.btn {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  outline: none !important;
  border: none !important;
  cursor: pointer !important;
  white-space: nowrap !important;
}

.btn--primary {
  background-color: #17BEBB !important;
  background-color: var(--primary) !important;
  color: #fff !important;
  border: 1px solid #17BEBB !important;
  border: 1px solid var(--primary) !important;
}

.btn--outline {
  background-color: white !important;
  color: #17BEBB !important;
  padding: 8px 20px !important;
  border: 1px solid #17BEBB !important;
  border: 1px solid var(--primary) !important;
  transition: all 0.3s ease-out !important;
}

.btn--medium {
  padding: 8px 20px !important;
  font-size: 18px !important;
}

.btn--large {
  padding: 8px 26px !important;
  font-size: 20px !important;
}

.btn--mobile {
  text-align: center !important;
  border-radius: 4px !important;
  width: 80% !important;
  text-decoration: none !important;
  font-size: 1.5rem !important;
  background-color: #17BEBB !important;
  color: #fff !important;
  padding: 14px 20px !important;
  border: 1px solid #17BEBB !important;
  transition: all 0.3s ease-out !important;
}

.btn--wide {
  padding: 8px 64px !important;
  font-size: 20px !important;
}

.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out !important;
  background: #fff !important;
  color: #17BEBB !important;
}

.btn--large:hover {
  color: #fff !important;
  background-color: #17BEBB !important;
  transition: all 0.2s ease-out !important;
}

.btn-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-decoration: none !important;
  padding: 8px 16px !important;
  height: 100% !important;
  width: 100% !important;
}

.btn--primary:hover {
  background: #fff !important;
  color: #17BEBB !important;
  border: 1px solid #17BEBB !important;
}

.carousel-control.left:hover, .carousel-control-prev:hover {
    background-image: none;
}

.carousel-control.right:hover, .carousel-control-next:hover {
    background-image: none;
}
.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-data-section {
  padding-left: 120px;
  padding-right: 120px;
  background-color: #fff;
}

.home-data-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
  display: flex;
}

.home-data-words-details {
  margin: 8px;
  color:  #696969;
  font-weight: 600;
}

.home-data-words-details p {
  font-size: 40px;
}

.bottom-details {
  margin-bottom: 30px;
}

.bottom-details-bg-colour {
  background-color: #c2f0ef;
  margin-bottom: 30px;
}

.home-data-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}

.home-card-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin: 40px 120px;
}

.home-card img {
  max-width: 180px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center center;
}

.home-card-section {
  background-color: #fff;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-card-container {
  padding: 40px 120px;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding-top: 0px;
}

.home-card {
  padding: 40px;
  border-radius: 4px;
  /* background: linear-gradient(45deg, #0000 0%, #17BEBB 100%); */
  background-color: #17BEBB;
  box-shadow: 10px 10px 30px rgba(0,0,0,0.1);
  text-align: left;
}

.home-card p {
  font-weight: 600;
  font-size: 24px;
  color: #FFF;
  text-align: center;
  padding-bottom: 16px;
}

.home-card span {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  font-style: italic;

  justify-content: center;
  align-items: center;
  display: flex;

  margin-bottom: 16px;
}


.home-card h1 {
  font-size: 24px;
  color: black;

  justify-content: center;
  align-items: center;
  display: flex;

  margin-bottom: 20px;
}


@media screen and (max-width: 960px) {
  
  .bottom-details {
    margin-bottom: 0px;
  }

  .home-data-words-details {
    font-weight: 400;
    font-size: 14px;
   
    margin: auto;
    width: 50%;
  
    padding: 8px 0px;
  }
  
  .home-data-details{
    justify-content: left;
 
  }

  .home-data-words-details p {
    font-weight: 600;
    font-size: 24px;
  }

  .home-data-section {
    background-color: #fff;
  }

  .home-data-container {
    display:contents;
    padding: 24px;
  }

  .home-card {
    padding: 20px;
  }

  .home-card-section {
    padding: 20px 20px;
  }

  .home-card-header {
    font-size: 20px;
    margin: 16px 0px;
    text-align: center;
  }

.home-card p {
  font-size: 18px;
  margin-bottom: 8px;
}

.home-card span {
  font-size: 14px;
}

.home-card-container {
  padding: 0px 0px;
  padding-bottom: 16px;
  grid-gap: 1em;
}
}

.card-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin: 40px 120px 20px 120px;
  background-color: #c2f0ef;
  border-bottom: 0px;
}

.card-header2 {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin: 40px 120px 20px 120px;
}

.card-section {
  background-color: #c2f0ef;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  align-items: center; 
}

.top-banner-header {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.top_banner_wrapper {
  justify-content: center;
  align-items: center;
  padding: 30px 80px;
  top: -40px;
  position: relative;
  background: #17BEBB;
  border-radius: 4px;
  max-width: 1200px;
}

.subheader_wrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 100px 10px 100px;
  font-size: 20px !important;
  color: #696969;
}

.donate_subheaders {
  margin-bottom: 16px;
  font-size: 18px;
  color: #696969;
}

.font_italic {
  font-style: italic;
}

.donate_large_subheaders {
  margin-bottom: 16px;
  font-size: 28px;
  color: #696969;
}

.endheader_wrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 100px 40px 100px;
}

.donate_endheaders {
  margin-bottom: 4px;
}

.top-banner-subtitles {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: #fff;
}

.card-container {
  padding: 40px 120px;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding-top: 0px;
}

.card {
  padding: 2em;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 10px 10px 30px rgba(0,0,0,0.1);
  text-align: center;
}


.card p {
  font-weight: 600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 16px;
  text-align: center;
}

.card h1 {
  font-weight:300;
  font-size: 16px;
  color: #696969;
  min-height: 100px;
  letter-spacing: 1px;

}

.card-container :hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: 0.3s ease-out;
}

@media screen and (max-width: 1024px) {
  .endheader_wrapper {
    padding: 30px 100px 40px 100px;
  }

  .btn--medium {
    padding: 10px 12px !important; 
    font-size: 16px !important;
  }

  .top_banner_wrapper {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px;
    top: 0px;
    background: none;
    color: #696969;
    padding-bottom: 16px;
  }

  .top-banner-header {
    color: #696969;
    font-size: 24px;
  }

  .top-banner-subtitles {
    color: #696969;
    font-size: 16px;
  }

  .card-section {
    padding: 20px 20px;
  }

  .card-header {
    font-size: 20px;
    margin: 16px 0px;
    text-align: center;
  }

.card p {
  font-size: 18px;
  margin-bottom: 8px;
}

.card span {
  font-size: 14px;
}

.card-container {
  padding: 0px 0px;
  grid-gap: 1em;
}
}

.hover-image-container {
  position: relative;
  width: 100%;
}

.hover-image {
  opacity: 1;
  
  width: 110px;
  height: 110px;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;

  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 8px;
}

.hover-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.hover-image-container:hover .hover-image {
  opacity: 0.3;
}

.hover-image-container:hover .hover-middle {
  opacity: 1;
}

.team-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}

.team-details-header {
  text-align:  center;
  /* text-decoration: underline; */
  font-weight: 600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 24px;
  margin-top: 20px;
}

.team-section {
  padding: 80px 160px;
  background-color: #c2f0ef;
}

.team-img {
  border-radius: 50%;
  vertical-align: middle;
  width: 110px;
  height: 110px;
  margin-bottom: 8px;
  /* margin-left: 60px; */
}

.team-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.team-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
}

.team-details p {
  font-weight:600;
  font-size: 16px;
  color: #696969;
  margin-bottom: 4px;
}

.team-details span {
  font-weight:300;
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
  text-align: center;
}

.team-imagine :hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: 0.3s ease-out;
}

@media screen and (max-width: 960px) {

  .hover-image {
    width: 80px;
    height: 80px;
  }

  .team-section {
    padding: 20px 20px;
  }

  .team-header {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .team-details p {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .team-details span {
    font-size: 14px;
  }

  .team-container {
    grid-gap: 1em;
  }

  .team-img {
    border-radius: 50%;
    vertical-align: middle;
    width: 80px;
    height: 80px;
    margin-bottom: 8px;
    /* margin-left: 60px; */
  }

  .team-details-header {
    font-size: 18px;
  }

  
.team-details-header {
  margin-bottom: 16px;
  margin-top: 0px;
}
}

.events-container {
  /* ``min-height: 100vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background-color: #fff;
  padding-bottom: 80px;
}

.timeline {
  width: 90%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.timeline ul {
  list-style: none;
}

.timeline ul li {
  padding: 20px;
  background-color: #c2f0ef;
  color: black;
  border-radius: 10px;
  margin-bottom: 20px;
}

.timeline ul li:last-child {
  margin-bottom: 0;
}

.timeline-content h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}

.timeline-content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}

.timeline-content .date {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 768px) {
  .events-container {
    padding-top: 0px !important;
  }

  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: gray;
  }

  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: -70px;
  }

  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    border-radius: 20px 0px 20px 20px;
  }

  .timeline ul li:nth-child(even) {
    float: right;
    clear: left;
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    border-radius: 0px 20px 20px 20px;
  }

  .timeline ul li::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: gray;
    top: 0px;
  }

  .timeline ul li:nth-child(odd)::before {
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    right: -30px;
  }

  .timeline ul li:nth-child(even)::before {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: -30px;
  }

  .timeline-content .date {
    position: absolute;
    top: -30px;
  }

  .timeline ul li:hover::before {
    background-color: aqua;
  }
}

@media screen and (max-width: 767px) {
  .events-container {
    padding-top: 0px !important;
    padding-bottom: 8px !important;
  }
  .team-container {
    
  }

  .timeline ul{
    padding-left: 0px;
  }
}

.credit-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
  /* background-color: yellow; */
}

.credit-section {
  padding: 60px 160px;
  /* background-color: red; */
}

.credit-img {
  vertical-align: middle;
  height: 110px;
  margin-bottom: 0px;
  margin-left: 24px;
  /* background-color: green; */
}

.credit-container {
  display: grid;
  /* grid-gap: 2em;   */
  grid-template-columns: auto auto auto auto;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
}

.credit-details {
  /* background-color: pink; */
  padding: 1em;
  justify-content: center;
  text-align: center;
}

.top-credit-details {
  padding: 1em;
  justify-content: center;
  text-align: center;
  margin: auto;
  display: flex;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.top-credit-details p {
  font-weight:600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 24px;
}

.credit-details p {
  font-weight:600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 24px;
}

.credit-details span {
  font-weight:300;
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .credit-section {
    padding: 20px 20px;
  }

  .credit-img {
    vertical-align: middle;
    width: auto;
    height: 80px;
    margin-bottom: 20px;
    margin-left: 8px;

  }

  .credit-header {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .credit-details {
    /* background-color: pink; */
    padding: 0em;
    justify-content: center;
    text-align: center;
  
  }

  .top-credit-details {
    padding: 0em;
    justify-content: center;
    text-align: center;
    display: grid;
    grid-gap: 0px;
  }

.credit-details p {
  font-size: 18px;
  margin-bottom: 12px;
}

.top-credit-details p {
  font-size: 18px;
  margin-bottom: 12px;
}

.credit-details span {
  font-size: 14px;
}

}

.text-content-section {
    color: #fff;
    max-width: 1300px; 
    margin: 40px auto; 
    margin-bottom: 120px;
}

.text-over-effect:hover {
    color: #fff;
    background-color: #696969;
    transition: all 0.2s ease-out !important;
}

.text-content-header {
    font-size: 40px;
    color: #696969;
    font-weight: 600;
    text-align: left;
    border-bottom: 0px;
    margin-bottom: 4px;
    width: 100%;
}

.text-content-subheader {
    font-size: 16px;
    color: #696969;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.text-col {
    padding-right: 60px;
    padding-left: 15px;
    flex: 1 1;
    max-width: 50%;
    flex-basis: 50%;
    margin-top: 28px;
    margin-bottom: 28px;
}

.text-content-subtitle {
    max-width: 500px;
    margin-bottom: 35px;
    line-height: 24px;
    color: #696969;
}

.text-content-subtitle h2 {
    font-size: 40px;
}

.text-content-subtitle p {
    font-size: 14px;
    margin-bottom: 16px;
}

.text-content-underline {
    padding: 4px 8px;
    border-bottom: 1px solid black;
    cursor: pointer;
    font-size: 24px;
}

.text-content-big-subtitle {
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    .text-content-subtitle h2 {
        font-size: 24px;
        padding-top: 12px;
    }

    .text-content-header {
        font-size: 32px;
    }
    
    .text-content-subtitle p {
        font-size: 14px;
        margin-bottom: 16px;
    }
    
    .text-content-underline {
        padding: 4px 8px;
        border-bottom: 1px solid black;
        cursor: pointer;
        font-size: 24px;
    }
    
    .text-content-big-subtitle {
        font-size: 16px;
    }
    
    .text-col p {
        margin-bottom: 16px;
    }

    .text-content-section{
        padding: 30px;
        margin: 0px;
    }

    .text-col{
        padding-right:16px;
        padding-left: 16px;
        max-width: 100%;
    }

    .text-content-big-subtitle {
        font-size: 16px;
    }

    .content-subtitle {
        font-size: 16px;
        margin-bottom: 16px;
        text-align: left;
    }

    .content-header {
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .text-content-section {
        margin: 16px;
        padding-top: 0px; 
    }

    .row hr {
        margin: 32px 0px;
    }

    .text-content-subheader {
        font-size: 16px;
    }

    .content-header {
        margin-bottom: 16px;
    }

    .content-text-wrapper {
        padding-bottom: 40px;
    }

    .row {
        flex-direction: column !important;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 0px;
    }

    .content-subtitle {
        text-align: center;
    }

    .text-col {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .text-content-subtitle {
        margin-bottom: 12px;
    }

    .text-content-underline {
        padding: 0px 0px;
        border-bottom: 1px solid black;
        cursor: pointer;
        font-size: 20px;
    
    }
}


.solution-content-section {
    color: #fff;
    max-width: 1300px; 
    margin: 40px auto; 
}

.product-content-subtitle {
    margin-bottom: 34px;
    line-height: 24px;
    color: #696969;
}

.product-content-subtitle p {
    font-size: 20px;
}

.fullscreen {
    height: 84vh;
}

.product-content-subtitle-marginTop {
    margin-top: 40px;
}

.content-row {
    align-items: center;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1 1;
    max-width: 50%;
    flex-basis: 50%;
}

.content-text-wrapper {
    max-width: 540px;
    padding-top: 0;
}

.solution-title-wrapper {
    text-align: left;
}

.content-header {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.dark {
    color: #696969;
}

.content-subtitle {
    max-width: 500px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 24px;
}

@media screen and (max-width: 1024px) {
    .solution-content-section{
        margin: 20px;
    }

    .fullscreen {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .product-content-subtitle-marginTop {
        margin-top: 20px;
    }

    .product-content-subtitle p {
        font-size: 16px;
        text-align: left;
    }

    .solution-title-wrapper {
        margin-bottom: 16px;
        margin-top: 12px;
        text-align: left;
    }

    .product-content-subtitle{
        margin-bottom: 0px;
    }

    .buttons-section {
        justify-content: left !important;
    }

    .container-sol {
        padding-right: 20px !important;
        padding-left: 20px !important;
        padding-bottom: 40px !important;
        padding-top: 20px !important;
    }

    .content-img {
        padding: 0;
    }

    .col {
        padding: 0px;
    }

    .sol-col {
        padding: 0px 20px 0px 20px;
    }

    .content-section {
        padding: 30px;
        padding-bottom: 0px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0px;
    }

    .content-subtitle {
        font-size: 16px;
        margin-bottom: 16px;
        text-align: left;
    }

    .content-header {
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .solution-content-section {
        margin: 16px;
        padding-top: 0px; 
    }

    .content-header {
        margin-bottom: 16px;
        text-align: center;
    }

    .content-text-wrapper {
        padding-bottom: 0px;
    }

    .row {
        flex-direction: column !important;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 0px;
    }

    .sol-col {
        margin-bottom: 20px;
    }

    .content-subtitle {
        text-align: center;
    }

    .buttons-section {
        justify-content: center !important;
    }
}

/* .donate_subheaders {
  margin-bottom: 16px;
  font-size: 18px;
  color: #696969;
} */

.endheader_donate_wrapper {
  text-align: center;
  max-width: 1000px;
  margin-top: 8px;
}

.donate_top_banner_wrapper {
    justify-content: center;
    align-items: center;
    padding: 20px 160px;
    position: relative;
    background: #17BEBB;
    border-radius: 4px;
    max-width: 1200px;
    min-width: 800px;
    top: -40px;
}
  
.donate_header_wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 16px;
    position: relative;
    justify-content: center;
    align-items: center; 
}

.donate-middle-section {
  background-color: #c2f0ef;
  padding: 0px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .endheader_donate_wrapper {
    padding: 0px;
  }
  .header_wrapper {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px;
    top: 0px;
    background: none;
    color: #696969;
    padding-bottom: 16px;
  }

  .donate-middle-section {
    padding: 40px 20px 0px 20px;
  }

  .donate_top_banner_wrapper {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px;
    top: 0px;
    background: none;
    color: #696969;
    padding-bottom: 16px;
  }
}

.volunteer-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.volunteer-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 40px;
  font-style: italic;
}


.volunteer-section {
  padding: 80px 160px;
  background-color: #fff;
}

.hover-image-container {
  position: relative;
  width: 100%;
}

.hover-image {
  opacity: 1;
  
  width: 110px;
  height: 110px;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;

  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 8px;
}

.hover-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 600;
}

.hover-image-container:hover .hover-image {
  opacity: 0.3;
}

.hover-image-container:hover .hover-middle {
  opacity: 1;
}


.volunteer-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.volunteer-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .volunteer-section {
    padding: 40px 20px;
  }

  .volunteer-header {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .hover-image {
    width: 80px;
    height: 80px;
  }

  /* .volunteer-container {
    grid-gap: 1em;
  } */

  .volunteer-container {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }

  .volunteer-subtitles{
    margin-bottom: 36px;
  }
  
}

.donate-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.donate-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 40px;
}

.donate-details-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 10px;
}

.italic{
  font-style: italic;
}

.underline{
  text-decoration: underline;
}

.vs-middle {
  background-color: '#2f0ef';
}

.donate-section {
  padding: 0px 160px 40px 160px;
  background-color: #c2f0ef;
}

.donate-img {
  border-radius: 50%;
  vertical-align: middle;
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}

.donate-container {
  display: grid;
  grid-gap: 1em;
  /* grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
}

.donate-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
}

.donate-details p {
  font-weight:600;
  font-size: 16px;
  color: #696969;
  margin-bottom: 4px;
}

.donate-details span {
  font-weight:300;
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
  text-align: center;
}

.donate-details :hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: 0.3s ease-out;
}

@media screen and (max-width: 960px) {
  .donate-section {
    padding: 20px 20px 40px 20px;
  }

  .donate-header {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .donate-subtitles{
    margin-bottom: 36px;
  }

.donate-details p {
  font-size: 18px;
  margin-bottom: 8px;
}

.donate-details span {
  font-size: 14px;
}

.donate-container {
  grid-gap: 1em;
}
}

.corporate-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.corporate-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 40px;
}

.corporate-details-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 10px;
}

.italic{
  font-style: italic;
}

.underline{
  text-decoration: underline;
}

.vs-middle {
  background-color: '#2f0ef';
}

.corporate-section {
    max-width: 1350px; 
    margin: 40px auto 100px auto; 
    background-color: #fff;
}

.corporate-img {
  border-radius: 50%;
  vertical-align: middle;
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}

.corporate-container {
  display: grid;
  grid-gap: 1em;
  /* grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
}

.corporate-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
}

.corporate-details p {
  font-weight:600;
  font-size: 16px;
  color: #696969;
  margin-bottom: 4px;
}

.corporate-details span {
  font-weight:300;
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
  text-align: center;
}

.corporate-details :hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: 0.3s ease-out;
}

@media screen and (max-width: 960px) {
  .corporate-section {
    padding: 0px 20px;
  }

  .corporate-header {
    font-size: 20px;
    margin-bottom: 16px;
  }

.corporate-details p {
  font-size: 18px;
  margin-bottom: 8px;
}

.corporate-details span {
  font-size: 14px;
}

.corporate-container {
  grid-gap: 1em;
}

.corporate-subtitles{
    margin-bottom: 36px;
  }
}

.footer-container {
    background-color: #1c2237;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

/* .footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
} */

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 24px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
    color: #b1b1b1;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 20px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights {
    color: #fff;
}

@media screen and (max-width: 1024px) {
    .footer-container {
        position: absolute;
        background-color: #1c2237;
        bottom: 0;
        width: 100%;
        padding-bottom: 0px;
        height: 5rem;
    }

    .social-media-wrap {
        margin: 16px;
    }
}

@media screen and (max-width: 767px) {
    .website-rights {
        color: #fff;
        padding-bottom: 8px;
    }

    .footer-container {
        position: absolute;
        background-color: #1c2237;
        bottom: 0;
        width: 100%;
        padding-bottom: 0px;
        /* padding-top: 0px; */
        height: 6rem;
    }

    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }
/* 
    .btn {
        width: 100%;
    } */

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}

