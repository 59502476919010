.volunteer-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.volunteer-subtitles{
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin-bottom: 40px;
  font-style: italic;
}


.volunteer-section {
  padding: 80px 160px;
  background-color: #fff;
}

.hover-image-container {
  position: relative;
  width: 100%;
}

.hover-image {
  opacity: 1;
  
  width: 110px;
  height: 110px;
  transition: .5s ease;
  backface-visibility: hidden;

  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 8px;
}

.hover-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 600;
}

.hover-image-container:hover .hover-image {
  opacity: 0.3;
}

.hover-image-container:hover .hover-middle {
  opacity: 1;
}


.volunteer-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.volunteer-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .volunteer-section {
    padding: 40px 20px;
  }

  .volunteer-header {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .hover-image {
    width: 80px;
    height: 80px;
  }

  /* .volunteer-container {
    grid-gap: 1em;
  } */

  .volunteer-container {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }

  .volunteer-subtitles{
    margin-bottom: 36px;
  }
  
}
