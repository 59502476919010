.card-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin: 40px 120px 20px 120px;
  background-color: #c2f0ef;
  border-bottom: 0px;
}

.card-header2 {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin: 40px 120px 20px 120px;
}

.card-section {
  background-color: #c2f0ef;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  align-items: center; 
}

.top-banner-header {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.top_banner_wrapper {
  justify-content: center;
  align-items: center;
  padding: 30px 80px;
  top: -40px;
  position: relative;
  background: #17BEBB;
  border-radius: 4px;
  max-width: 1200px;
}

.subheader_wrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 100px 10px 100px;
  font-size: 20px !important;
  color: #696969;
}

.donate_subheaders {
  margin-bottom: 16px;
  font-size: 18px;
  color: #696969;
}

.font_italic {
  font-style: italic;
}

.donate_large_subheaders {
  margin-bottom: 16px;
  font-size: 28px;
  color: #696969;
}

.endheader_wrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 100px 40px 100px;
}

.donate_endheaders {
  margin-bottom: 4px;
}

.top-banner-subtitles {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: #fff;
}

.card-container {
  padding: 40px 120px;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding-top: 0px;
}

.card {
  padding: 2em;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 10px 10px 30px rgba(0,0,0,0.1);
  text-align: center;
}


.card p {
  font-weight: 600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 16px;
  text-align: center;
}

.card h1 {
  font-weight:300;
  font-size: 16px;
  color: #696969;
  min-height: 100px;
  letter-spacing: 1px;

}

.card-container :hover {
  transform: scale(1.06);
  transition: 0.3s ease-out;
}

@media screen and (max-width: 1024px) {
  .endheader_wrapper {
    padding: 30px 100px 40px 100px;
  }

  .btn--medium {
    padding: 10px 12px !important; 
    font-size: 16px !important;
  }

  .top_banner_wrapper {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px;
    top: 0px;
    background: none;
    color: #696969;
    padding-bottom: 16px;
  }

  .top-banner-header {
    color: #696969;
    font-size: 24px;
  }

  .top-banner-subtitles {
    color: #696969;
    font-size: 16px;
  }

  .card-section {
    padding: 20px 20px;
  }

  .card-header {
    font-size: 20px;
    margin: 16px 0px;
    text-align: center;
  }

.card p {
  font-size: 18px;
  margin-bottom: 8px;
}

.card span {
  font-size: 14px;
}

.card-container {
  padding: 0px 0px;
  grid-gap: 1em;
}
}
