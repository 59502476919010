@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

@media screen and (max-width: 1024px) {
    #page-container {
        padding-bottom: 5rem;
    }
}

/* #content-wrap {
    padding-bottom: 2.5rem;  
  } */
