.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-data-section {
  padding-left: 120px;
  padding-right: 120px;
  background-color: #fff;
}

.home-data-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
  display: flex;
}

.home-data-words-details {
  margin: 8px;
  color:  #696969;
  font-weight: 600;
}

.home-data-words-details p {
  font-size: 40px;
}

.bottom-details {
  margin-bottom: 30px;
}

.bottom-details-bg-colour {
  background-color: #c2f0ef;
  margin-bottom: 30px;
}

.home-data-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}

.home-card-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin: 40px 120px;
}

.home-card img {
  max-width: 180px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center center;
}

.home-card-section {
  background-color: #fff;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-card-container {
  padding: 40px 120px;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding-top: 0px;
}

.home-card {
  padding: 40px;
  border-radius: 4px;
  /* background: linear-gradient(45deg, #0000 0%, #17BEBB 100%); */
  background-color: #17BEBB;
  box-shadow: 10px 10px 30px rgba(0,0,0,0.1);
  text-align: left;
}

.home-card p {
  font-weight: 600;
  font-size: 24px;
  color: #FFF;
  text-align: center;
  padding-bottom: 16px;
}

.home-card span {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  font-style: italic;

  justify-content: center;
  align-items: center;
  display: flex;

  margin-bottom: 16px;
}


.home-card h1 {
  font-size: 24px;
  color: black;

  justify-content: center;
  align-items: center;
  display: flex;

  margin-bottom: 20px;
}


@media screen and (max-width: 960px) {
  
  .bottom-details {
    margin-bottom: 0px;
  }

  .home-data-words-details {
    font-weight: 400;
    font-size: 14px;
   
    margin: auto;
    width: 50%;
  
    padding: 8px 0px;
  }
  
  .home-data-details{
    justify-content: left;
 
  }

  .home-data-words-details p {
    font-weight: 600;
    font-size: 24px;
  }

  .home-data-section {
    background-color: #fff;
  }

  .home-data-container {
    display:contents;
    padding: 24px;
  }

  .home-card {
    padding: 20px;
  }

  .home-card-section {
    padding: 20px 20px;
  }

  .home-card-header {
    font-size: 20px;
    margin: 16px 0px;
    text-align: center;
  }

.home-card p {
  font-size: 18px;
  margin-bottom: 8px;
}

.home-card span {
  font-size: 14px;
}

.home-card-container {
  padding: 0px 0px;
  padding-bottom: 16px;
  grid-gap: 1em;
}
}
