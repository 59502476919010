.text-content-section {
    color: #fff;
    max-width: 1300px; 
    margin: 40px auto; 
    margin-bottom: 120px;
}

.text-over-effect:hover {
    color: #fff;
    background-color: #696969;
    transition: all 0.2s ease-out !important;
}

.text-content-header {
    font-size: 40px;
    color: #696969;
    font-weight: 600;
    text-align: left;
    border-bottom: 0px;
    margin-bottom: 4px;
    width: 100%;
}

.text-content-subheader {
    font-size: 16px;
    color: #696969;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.text-col {
    padding-right: 60px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
    margin-top: 28px;
    margin-bottom: 28px;
}

.text-content-subtitle {
    max-width: 500px;
    margin-bottom: 35px;
    line-height: 24px;
    color: #696969;
}

.text-content-subtitle h2 {
    font-size: 40px;
}

.text-content-subtitle p {
    font-size: 14px;
    margin-bottom: 16px;
}

.text-content-underline {
    padding: 4px 8px;
    border-bottom: 1px solid black;
    cursor: pointer;
    font-size: 24px;
}

.text-content-big-subtitle {
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    .text-content-subtitle h2 {
        font-size: 24px;
        padding-top: 12px;
    }

    .text-content-header {
        font-size: 32px;
    }
    
    .text-content-subtitle p {
        font-size: 14px;
        margin-bottom: 16px;
    }
    
    .text-content-underline {
        padding: 4px 8px;
        border-bottom: 1px solid black;
        cursor: pointer;
        font-size: 24px;
    }
    
    .text-content-big-subtitle {
        font-size: 16px;
    }
    
    .text-col p {
        margin-bottom: 16px;
    }

    .text-content-section{
        padding: 30px;
        margin: 0px;
    }

    .text-col{
        padding-right:16px;
        padding-left: 16px;
        max-width: 100%;
    }

    .text-content-big-subtitle {
        font-size: 16px;
    }

    .content-subtitle {
        font-size: 16px;
        margin-bottom: 16px;
        text-align: left;
    }

    .content-header {
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .text-content-section {
        margin: 16px;
        padding-top: 0px; 
    }

    .row hr {
        margin: 32px 0px;
    }

    .text-content-subheader {
        font-size: 16px;
    }

    .content-header {
        margin-bottom: 16px;
    }

    .content-text-wrapper {
        padding-bottom: 40px;
    }

    .row {
        flex-direction: column !important;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 0px;
    }

    .content-subtitle {
        text-align: center;
    }

    .text-col {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .text-content-subtitle {
        margin-bottom: 12px;
    }

    .text-content-underline {
        padding: 0px 0px;
        border-bottom: 1px solid black;
        cursor: pointer;
        font-size: 20px;
    
    }
}

