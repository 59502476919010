.hover-image-container {
  position: relative;
  width: 100%;
}

.hover-image {
  opacity: 1;
  
  width: 110px;
  height: 110px;
  transition: .5s ease;
  backface-visibility: hidden;

  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 8px;
}

.hover-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.hover-image-container:hover .hover-image {
  opacity: 0.3;
}

.hover-image-container:hover .hover-middle {
  opacity: 1;
}

.team-header {
  font-size: 36px;
  color: #696969;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}

.team-details-header {
  text-align:  center;
  /* text-decoration: underline; */
  font-weight: 600;
  font-size: 24px;
  color: #696969;
  margin-bottom: 24px;
  margin-top: 20px;
}

.team-section {
  padding: 80px 160px;
  background-color: #c2f0ef;
}

.team-img {
  border-radius: 50%;
  vertical-align: middle;
  width: 110px;
  height: 110px;
  margin-bottom: 8px;
  /* margin-left: 60px; */
}

.team-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.team-details {
  padding: 0em;
  text-align: center;
  justify-content: center;
}

.team-details p {
  font-weight:600;
  font-size: 16px;
  color: #696969;
  margin-bottom: 4px;
}

.team-details span {
  font-weight:300;
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
  text-align: center;
}

.team-imagine :hover {
  transform: scale(1.06);
  transition: 0.3s ease-out;
}

@media screen and (max-width: 960px) {

  .hover-image {
    width: 80px;
    height: 80px;
  }

  .team-section {
    padding: 20px 20px;
  }

  .team-header {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .team-details p {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .team-details span {
    font-size: 14px;
  }

  .team-container {
    grid-gap: 1em;
  }

  .team-img {
    border-radius: 50%;
    vertical-align: middle;
    width: 80px;
    height: 80px;
    margin-bottom: 8px;
    /* margin-left: 60px; */
  }

  .team-details-header {
    font-size: 18px;
  }

  
.team-details-header {
  margin-bottom: 16px;
  margin-top: 0px;
}
}
