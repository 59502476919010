.content-section {
  color: #fff;
  padding: 80px;  
}

.fullscreen {
  height: 84vh;
}

.buttons-section {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}

.blue-background {
  background-color: #c2f0ef;
}

.topheader {
  color:#696969;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  flex-direction: wrap;
  margin-bottom: 40px;
}

.data_section {
  /* background: #17BEBB; */
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.data_section ul {
  flex-direction: row;
  justify-content: center;
  display: flex;
  list-style: none;
}

.data_section span {
  font-size: 16px;
  font-weight: 300;
  color: #696969;
}

.data_section p {
  font-size: 40px;
  font-weight: 600;
  color: #696969;
}

.data_section li {
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 20px;
  padding-right: 16px;
  list-style: none;
}


.content-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.content-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  /* padding-bottom: 60px; */
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.content-title-wrapper {
    margin-bottom: 16px;
    text-align: left;
}

.content-header {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.dark {
  color: #696969;
}

.content-subtitle {
  max-width: 500px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
}

.pointer-subtitle {
    /* max-width: 500px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px; */
}

.content-img-wrapper {
  max-width: 555px;
}

.content-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  /* .btn--large {
    padding:  10px 20px !important;
    font-size: 16px !important;
  } */

  .fullscreen {
    height:fit-content;
  }

  .content-title-wrapper {
    margin-bottom: 12px;
    margin-top: 12px;
    text-align: center;
}

  .content-img-wrapper {
    justify-content: center !important;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  

  .buttons-section {
    justify-content: left !important;
  }

  .container {
    padding-right: 30px !important;
    padding-left: 30px !important;
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }

  .content-img {
    padding: 0;
  }
  
  .col {
    padding: 0px;
  }

  .content-section {
    padding: 0px;
    padding-bottom: 0px;
    height:fit-content;
  }

  .content-subtitle {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: left;
  }

  .content-header {
    font-size: 24px;
    margin-bottom: 16px;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .content-header {
    margin-bottom: 16px;
    text-align: center;
  }

  .content-text-wrapper {
    padding-bottom: 0px;
  }
  
  .content-img {
    padding: 0;
  }
  
  .row {
    flex-direction: column !important;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 0px;
  }

  .content-subtitle {
    text-align: center;
  }

  .buttons-section {
    justify-content: center !important;
  }
}
